import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../components/Layout'

const bsl = () => {
  return (
    <Fragment>
      <Layout>
        <Helmet>
          <title>Legals BSL | Lenses.io</title>
          <meta name="description" content="Legals BSL | Lenses.io" />
          <meta name="keywords" content="Legals BSL | Lenses.io" />
        </Helmet>
        <section className="container-1">
          <div className="px-2 text-dark f-16 my-5 py-5">
            <div className="field-item even" property="content:encoded">
              <h2 dir="ltr">
                <strong>Business Source License 1.0</strong>
              </h2>

              <p dir="ltr">
                <u>Licensor</u>: &nbsp;Lenses.io Ltd
              </p>

              <p dir="ltr">
                <u>Software</u>: Lenses.io Apache Kafka tools. &nbsp;The
                Software is © 2016-2020 Lenses.io
              </p>

              <p dir="ltr">
                <u>Use Limitation</u>: Usage of the software is free when your
                application uses the Software with a total of less than five
                Kafka server instances for production purposes.
              </p>

              <p dir="ltr">
                <u>Change Date</u>: 2022-01-01
              </p>

              <p dir="ltr">
                <u>Change License</u>: &nbsp;Apache-2.0 license.
              </p>

              <p dir="ltr">_____________________________________________</p>

              <p dir="ltr">
                You are granted limited license to the Software under this
                Business Source License. &nbsp;Please read this Business Source
                License carefully, particularly the Use Limitation set forth
                above. &nbsp;
              </p>

              <p dir="ltr">
                Subject to the Use Limitation, Licensor grants you a
                non-exclusive, worldwide (subject to applicable laws) license to
                copy, modify, display, use, create derivative works, and
                redistribute the Software until the Change Date. If your use of
                the Software exceeds, or will exceed, the foregoing limitations
                you MUST obtain alternative licensing terms for the Software
                directly from Licensor. &nbsp;For the avoidance of doubt, prior
                to the Change Date, there is no Use Limitations for
                non-production purposes.
              </p>

              <p dir="ltr">
                After the Change Date, this Business Source License will convert
                to the Change License and your use of the Software, including
                modified versions of the Software, will be governed by such
                Change License.
              </p>

              <p dir="ltr">
                All copies of original and modified Software, and derivative
                works of the Software, are subject to this Business Source
                License. &nbsp;&nbsp;This Business Source License applies
                separately for each version of the Software and the Change Date
                will vary for each version of the Software released by Licensor.
              </p>

              <p dir="ltr">
                You must conspicuously display this Business Source License on
                each original or modified copy of the Software. If you receive
                the Software in original or modified form from a third party,
                the restrictions set forth in this Business Source License apply
                to your use of such Software.
              </p>

              <p dir="ltr">
                Any use of the Software in violation of this Business Source
                License will automatically terminate your rights under this
                Business Source License for the current and all future versions
                of the Software.
              </p>

              <p dir="ltr">
                You may not use the marks or logos of Licensor or its affiliates
                for commercial purposes without prior written consent from
                Licensor.
              </p>

              <p dir="ltr">
                TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE SOFTWARE AND ALL
                SERVICES PROVIDED BY LICENSOR OR ITS AFFILIATES UNDER OR IN
                CONNECTION WITH WITH THIS BUSINESS SOURCE LICENSE ARE PROVIDED
                ON AN “AS IS” AND “AS AVAILABLE” BASIS. YOU EXPRESSLY WAIVE ALL
                WARRANTIES, EXPRESS OR IMPLIED, INCLUDING (WITHOUT LIMITATION)
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                NON-INFRINGEMENT, TITLE, SYSTEM INTEGRATION, AND ACCURACY OF
                INFORMATIONAL CONTENT.
              </p>
            </div>
          </div>
        </section>
      </Layout>
    </Fragment>
  )
}

export default bsl
